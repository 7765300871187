export const imageDimension = (width: number, height: number): string => {
  return height > width ? 'vertical' : 'horizantal';
};

/**
 * normalized rgba, hex code and postcss variables depends on input
 * check whether input was color code or not,
 * if it is, return the free text, otherwise return postcss variable
 * @param {string} value
 * @returns {string} hexCode || rgbaValue || postcss variable
 */
export const getColorCode = (value: string) => {
  if (value.indexOf('#') > -1 || /rgb/.test(value)) {
    return value;
  }

  return `var(--${value})`;
};

export const getCookieDomain = () => {
  const domain = window.location.host;
  const cookieDomain = process.env.NEXT_PUBLIC_COOKIE_DOMAIN ?? '';

  return domain.indexOf(cookieDomain) <= 0 ? domain : cookieDomain;
};

export default {
  imageDimension,
  getColorCode,
  getCookieDomain,
};
