import { useRouter } from 'next/router';

import Link from 'components/common/Link';

import { LinkBuildingSectionProps } from 'interfaces/cms/content';
import { transformLocale } from 'lib';

function Desktop({
  id,
  subsectionTitle,
  contentLink,
}: LinkBuildingSectionProps) {
  const { locale } = useRouter();

  return (
    <div className="mb-[1.875rem] last:mb-0" id={`desktop-link-building-${id}`}>
      <div className="text-white font-bold mb-[0.9375rem]">
        {subsectionTitle}
      </div>
      <div className="border-b border-grayColor opacity-50 border-dashed"></div>

      <div className="grid grid-cols-4">
        {contentLink.map((subsection) => (
          <Link
            key={subsection.id}
            href={subsection.link}
            rel={subsection.linkRel}
            isExternalLink={subsection.type !== 'slug'}
            locale={transformLocale(locale)}
            passHref
            className="mt-[0.9375rem] text-sm text-white"
          >
            {subsection.title}
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Desktop;
