import {
  BlogsProps,
  BlogsTabProps,
  CustomerReviewsProps,
  FaqAndSeoProps,
  FaqAndSeoTabProps,
  LogoProps,
  NewsletterProps,
  TextBoxProps,
  VideoProps,
  LinkBuildingProps,
  ParagraphAndImageProps,
  ProductOverviewProps,
  SliderWithCtaProps,
  SlideShowProps,
  TableProps,
  ImpactTextProps,
  MobileFirstBannerProps,
  ProductListingModuleProps,
  ProductListingTwoColumnProps,
  CategoryModuleProps,
  ShortQuestionnaireProps,
  BannerInPageFormProps,
  LoanWizardProps,
  MapAndTextBoxProps,
  BiographyProps,
  BreadcrumbProps,
  YouMayAlsoLikeProps,
  ProductListingAndTabsProps,
  BiographyNewProps,
  BlogsTab2Props,
  ProductCardsListingProps,
} from 'interfaces/cms/content';

export enum DynamicContentTypes {
  BLOGS = 'blogs',
  BLOGSTAB = 'blogsTab',
  BLOGSTAB2 = 'blogsTab2',
  REVIEWS = 'reviews',
  FAQSEO = 'faqSeo',
  FAQSEOTAB = 'faqSeoTab',
  TEXTBOX = 'textBox',
  NEWSLETTER = 'newsletter',
  VIDEO = 'video',
  LOGOS = 'logos',
  LINKBUILDING = 'linkBuildingModule',
  PARAGRAPHIMAGE = 'paragraphImages',
  PRODUCTOVERVIEW = 'productOverview',
  SLIDERWITHCTA = 'sliderWithCta',
  SLIDESHOW = 'slideShow',
  TABLE = 'table',
  IMPACTTEXT = 'impactText',
  MOBILEFIRSTBANNER = 'mobileFirstBanner',
  PRODUCTLISTING = 'productListing',
  PRODUCTLISTINGTWOCOLUMNS = 'productListingTwoColumns',
  CATEGORY = 'categoryModule',
  SHORTQUESTIONNAIRE = 'shortQuestionnaire',
  BANNERINPAGEFORM = 'bannerInPageForm',
  LOANWIZARD = 'loanWizard',
  MAPANDTEXTBOX = 'mapAndTextbox',
  BIOGRAPHY = 'biography',
  BIOGRAPHYNEW = 'biographyNew',
  BREADCRUMB = 'breadcrumb',
  YOUMAYALSOLIKE = 'youMayAlsoLike',
  PRODUCTLISTINGANDTABS = 'productListingAndTabs',
  PRODUCTCARDSLISTING = 'productCardsListing',
  MODULETEMPLATE = 'moduleTemplate',
}

export interface ModuleTemplateProps {
  id: number;
  componentType: DynamicContentTypes.MODULETEMPLATE;
  content: Array<DynamicContentProps>;
}

export type DynamicContentProps =
  | NewsletterProps
  | TextBoxProps
  | FaqAndSeoProps
  | FaqAndSeoTabProps
  | CustomerReviewsProps
  | VideoProps
  | LogoProps
  | LinkBuildingProps
  | BlogsProps
  | BlogsTabProps
  | BlogsTab2Props
  | ParagraphAndImageProps
  | ProductOverviewProps
  | SliderWithCtaProps
  | SlideShowProps
  | TableProps
  | ImpactTextProps
  | MobileFirstBannerProps
  | ProductListingModuleProps
  | CategoryModuleProps
  | ShortQuestionnaireProps
  | ProductListingTwoColumnProps
  | BannerInPageFormProps
  | LoanWizardProps
  | MapAndTextBoxProps
  | BiographyProps
  | BiographyNewProps
  | BreadcrumbProps
  | YouMayAlsoLikeProps
  | ProductListingAndTabsProps
  | ProductCardsListingProps
  | ModuleTemplateProps;

type Changefreq =
  | 'always'
  | 'hourly'
  | 'daily'
  | 'weekly'
  | 'monthly'
  | 'yearly'
  | 'never';

export interface Page {
  id: number;
  attributes: {
    name: string;
    disable_global_notification: boolean;
    disable_global_popup: boolean;
    heart_pattern: boolean;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    locale: string;
    tenant: string;
    seo: {
      id: number;
      title: string;
      description: string;
      keywords: string;
      robots: string;
      canonical: string;
      changefreq: Changefreq;
      priority: number;
    };
    page_slug: {
      data: {
        id: number;
        attributes: {
          name: string;
          slug: string;
          createdAt: string;
          updatedAt: string;
          publishedAt: string;
          locale: string;
          tenant: string;
          breadcrumb_name: string | null;
        };
      };
    };
    notification: any; // TODO: Add notification type
    popup: any; // TODO: Add popup type
    content: Array<DynamicContentProps>;
    localizations: {
      data: Array<any>;
    };
  };
}
