import { createContext } from 'react';

type ProductTypesContextProps = {
  productTypes: any[];
  find: (item) => any;
};

const ProductTypesContext = createContext<ProductTypesContextProps>({
  productTypes: [],
  find: () => null,
});

export default ProductTypesContext;
