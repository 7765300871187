import { useState } from 'react';

const useDisclosure = () => {
  const [isOpen, setOpen] = useState(false);

  const onClose = () => setOpen(false);
  const onOpen = () => setOpen(true);
  const onToggle = () => setOpen((prev) => !prev);

  return { isOpen, onClose, onOpen, onToggle };
};

export default useDisclosure;
