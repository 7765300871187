const path = require('path');
const { availableLocales, defaultLocale } = require('./config');

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  i18n: {
    defaultLocale,
    locales: availableLocales,
  },
  ...(typeof window === 'undefined'
    ? { localePath: path.resolve('./public/locales') } // TODO: Check for localePath, if not exist, throw error
    : {}),
  serializeConfig: false,
};
