import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import CloseIcon from '@public/icons/close.svg';

export function Modal({ isOpen, onClose, children }) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.78)',
          backdropFilter: 'blur(2px)',
        }}
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0">
              <Dialog.Panel />
            </div>
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={clsx(
                'inline-block w-full max-w-md md:p-[3.75rem] my-8',
                'overflow-hidden text-left align-middle rounded-[1rem]',
                'transition-all transform bg-white shadow-xl',
                'px-0 md:px-[2.25rem]'
              )}
            >
              <CloseIcon
                onClick={onClose}
                width="30"
                height="30"
                viewBox="0 0 20 20"
                className="absolute right-[0.625rem] top-[0.625rem] cursor-pointer"
              />
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

interface ModalContentProps {
  className?: string;
  children: any;
}

export function ModalHeader({ className, children }: ModalContentProps) {
  return (
    <header
      className={clsx(
        className,
        'flex-[0_1_0%] py-4 px-6 text-[1.25rem] font-semibold'
      )}
    >
      {children}
    </header>
  );
}

export function ModalBody({ className, children }: ModalContentProps) {
  return (
    <div className={clsx(className, 'flex-[1_1_0%] py-2 px-6')}>{children}</div>
  );
}

export function ModalFooter({ className, children }: ModalContentProps) {
  return (
    <footer
      className={clsx(
        className,
        'flex items-center justify-center px-6 py-4 flex-col'
      )}
    >
      {children}
    </footer>
  );
}

export default { Modal, ModalHeader, ModalBody, ModalFooter };
