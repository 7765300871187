import { useState, useEffect } from 'react';
import Link from 'next/link';
import { transformLocale } from 'lib';
import useQueryParams from 'hooks/useQueryParams';

export interface BasicLinkProps {
  href?: string;
  locale?: string;
  passHref?: boolean;
  isExternalLink?: boolean;
  anchorStyle?: object;
  className?: string;
  dataTestId?: string;
  rel?: string;
  useUtmParams?: boolean;
}

function BasicLinkComponent({
  children,
  passHref = true,
  href,
  locale,
  isExternalLink,
  anchorStyle,
  className,
  dataTestId,
  rel,
  useUtmParams = false,
}: BasicLinkProps & { children?: React.ReactNode }) {
  const [isExternal, setIsExternal] = useState(false);
  const regex = /^https?:\/\//;

  const queryLink = useQueryParams(useUtmParams, href);

  useEffect(() => {
    const { hostname } = window.location;
    setIsExternal(isExternalLink && !queryLink.includes(hostname));
  }, [queryLink, isExternalLink]);

  if (regex.test(queryLink)) {
    return (
      <div className={className}>
        <a
          href={queryLink}
          style={anchorStyle}
          rel={`noopener noreferrer${rel ? ` ${rel}` : ''}`}
          target={isExternal ? '_blank' : '_self'}
          data-testid={dataTestId}
        >
          {children}
        </a>
      </div>
    );
  }

  return (
    <div className={className}>
      <Link
        href={href}
        locale={transformLocale(locale)}
        passHref={passHref}
        prefetch={false}
        style={anchorStyle}
        data-testid={dataTestId}
      >
        {children}
      </Link>
    </div>
  );
}

export default BasicLinkComponent;
