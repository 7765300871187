const isProd = process.env.ENVIRONMENT === 'production';

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
const cmsUrl = process.env.NEXT_PUBLIC_STRAPI_API_URL;
const ccUrl = process.env.CREDIT_CARD_URL;
const redirectUrl = process.env.NEXT_PUBLIC_REDIRECT_URL;

if (!baseUrl) {
  throw new Error('NEXT_PUBLIC_BASE_URL is not set');
}

const topLevelDomain = process.env.NEXT_PUBLIC_TOP_LEVEL_DOMAIN;

if (!topLevelDomain) {
  throw new Error('Top level domain is not set');
}

// This is a mapping of top level domain to default locales
const tldToDefaultLocaleMapping = {
  my: 'en-MY',
  ph: 'en-PH',
  vn: 'vi',
  sg: 'en-SG',
  asiadirect: 'asiadirect',
  th: 'th',
  id: 'id',
  en: 'en',
};

// This is a mapping of top level domain to default locales
const tldToLocaleMapping = {
  my: ['en-MY'],
  ph: ['en-PH'],
  vn: ['vi'],
  sg: ['en-SG'],
  asiadirect: ['asiadirect'],
  th: ['th', 'en'],
  id: ['id'],
  en: ['en'],
};

const defaultLocale = tldToDefaultLocaleMapping[topLevelDomain];

if (!defaultLocale) {
  throw new Error('Top level domain is not supported');
}

const availableLocales = tldToLocaleMapping[topLevelDomain];

if (!availableLocales) {
  throw new Error('Top level domain is not supported');
}

// This is a mapping of top level domain to available locales
const tldToSourceMapping = {
  my: 'en-MY|ms',
  ph: 'en-PH|fil',
  vn: 'vi',
  sg: 'en-SG',
  asiadirect: 'asiadirect',
  th: 'th|en',
  id: 'id',
  en: 'en',
};

const config = {
  isProd,
  cmsUrl,
  baseUrl,
  ccUrl,
  redirectUrl,
  defaultLocale,
  availableLocales,
  tldToSourceMapping,
  tldToDefaultLocaleMapping,
  tldToLocaleMapping,
  topLevelDomain,
};

module.exports = config;
