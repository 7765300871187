import clsx from 'clsx';
import Countdown, { zeroPad } from 'react-countdown';

import CloseIcon from '@public/icons/close.svg';

import Markdown from 'components/common/Markdown';
import styles from './Notification.module.scss';

export interface NotificationProps {
  content?: string;
  contentColor?: string;
  backgroundColor?: string;
  countdown?: Date;
  onClose?: () => void;
}

function Notification({
  content,
  contentColor,
  backgroundColor,
  countdown,
  onClose,
}: NotificationProps) {
  return (
    <div
      className="p-[0.9375rem] flex justify-center items-start md:items-center relative"
      style={{ backgroundColor, color: contentColor }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[20px] h-[20px] mt-[0.1875rem] md:mt-0"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <div className="inline pr-[1.875rem] ml-[0.9375rem]">
        <Markdown
          md={content}
          textColor={contentColor}
          textAlign="left"
          className={clsx('inline', styles.notification__content)}
        />
        {countdown && (
          <Countdown
            date={countdown}
            renderer={({ days, hours, minutes, seconds }) => (
              <div className="inline font-bold ml-[0.9375rem]">
                {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:
                {zeroPad(seconds)}
              </div>
            )}
          />
        )}
      </div>

      <CloseIcon
        fill="white"
        onClick={onClose}
        className="absolute right-[0.9375rem] mt-[0.1875rem] md:mt-0 cursor-pointer"
      />
    </div>
  );
}

export default Notification;
