import { useEffect, memo } from 'react';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Poppins, Kanit, Roboto } from 'next/font/google';
import Cookies from 'js-cookie';

import GTMHelper from 'lib/GTMHelper';

import Layout from 'components/Layout';
import ProductTypesContext from 'store/productTypes-context';
import nextI18NextConfig from '../../next-i18next.config';

import '@styles/globals.css';
import { isAsiadirect } from 'lib';

export const poppins = Poppins({
  subsets: ['latin'],
  weight: ['400', '700'],
  display: 'swap',
  variable: '--font-poppins',
});
export const kanit = Kanit({
  subsets: ['latin', 'thai'],
  weight: ['400', '700'],
  display: 'swap',
  variable: '--font-kanit',
});
export const roboto = Roboto({
  subsets: ['latin'],
  weight: ['100', '400', '500', '700'],
  display: 'swap',
  variable: '--font-roboto',
});

const useForceHtmlLangAttribute = (desiredLang: string) => {
  useEffect(() => {
    document.documentElement.lang = desiredLang;

    const langObserver = new MutationObserver(() => {
      if (document.documentElement.lang !== desiredLang) {
        document.documentElement.lang = desiredLang;
      }
    });
    langObserver.observe(document.documentElement, {
      attributeFilter: ['lang'],
    });

    return () => {
      langObserver.disconnect();
    };
  }, [desiredLang]);
};

const getQueryParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return JSON.stringify(params);
};

const getCookieDomain = () => {
  const domain = window.location.host;
  const cookieDomain = process.env.NEXT_PUBLIC_COOKIE_DOMAIN ?? '';

  return domain.indexOf(cookieDomain) <= 0 ? domain : cookieDomain;
};

const PRODUCT_OVERVIEW_STORAGE_KEY = 'productOverviewUrl';

function MyApp({ Component, pageProps }: any) {
  const { locale, asPath } = useRouter();

  useForceHtmlLangAttribute(isAsiadirect() ? 'th' : locale);

  useEffect(() => {
    if (window) {
      const storage = window.localStorage;
      const { hash } = window.location;
      const productOverviewUrl = storage.getItem(PRODUCT_OVERVIEW_STORAGE_KEY);

      if (
        productOverviewUrl &&
        productOverviewUrl !== asPath &&
        productOverviewUrl !== hash
      ) {
        GTMHelper.customRcHomeCategory(null);

        storage.removeItem(PRODUCT_OVERVIEW_STORAGE_KEY);
      }
    }
  }, [asPath]);

  useEffect(() => {
    // FIXME: Replace static language comparison with dynamic locales
    const homePage = `${locale === 'th' ? '/' : '/en'}`;
    const subPage = `${locale === 'th' ? '' : locale}${asPath}`;
    const page = asPath === '/' ? homePage : subPage;

    GTMHelper.pageView(pageProps.page?.seo?.title, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageProps, asPath]);

  // SET Cookie
  useEffect(() => {
    // Set Cookies
    Cookies.set('lgt_campaign_query_params', getQueryParams(), {
      expires: 3,
      path: '/',
      domain: getCookieDomain(),
    });

    Cookies.set('lgt_referer_url', window.location.href, {
      expires: 3,
      path: '/',
      domain: getCookieDomain(),
    });
  }, []);

  const gtmId = pageProps.settings?.gtmContainer;

  return (
    <div
      id="next-main"
      className={`${poppins.variable} ${kanit.variable} ${roboto.variable}`}
    >
      {/* Google Tag Manager */}
      {gtmId && (
        <Script
          type="rocketlazyloadscript"
          dangerouslySetInnerHTML={{
            __html: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;	j.addEventListener('load', function() {	var _ge = new CustomEvent('gtm_loaded', { bubbles: true });	d.dispatchEvent(_ge);}); f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${gtmId}');`,
          }}
          strategy="afterInteractive"
        />
      )}
      {/* End Google Tag Manager */}

      {pageProps.layout ? (
        <Layout
          layout={pageProps.layout}
          notifications={pageProps.notifications}
          popup={pageProps.popup}
          settings={pageProps.settings}
          seo={pageProps.page.seo}
          slug={pageProps?.page?.slug}
          staticLinkBuilding={pageProps.layout.staticLinkBuilding}
          breadcrumbs={pageProps.breadcrumbs}
        >
          <ProductTypesContext.Provider value={pageProps.productTypes}>
            <Component {...pageProps} key={asPath} />
          </ProductTypesContext.Provider>
        </Layout>
      ) : (
        <Component {...pageProps} key={asPath} />
      )}
    </div>
  );
}

export default memo(appWithTranslation(MyApp as any, nextI18NextConfig as any));
