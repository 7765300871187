import { useLocalStorage } from 'usehooks-ts';

import { Notification as NotificationType } from 'interfaces/cms/common';
import Notification from './Notification';

export interface NotificationBarProps {
  notifications: Array<NotificationType>;
}

const STORAGE_KEY = 'closed:notifications';

function NotificationBar({ notifications }: NotificationBarProps) {
  const [closedString, setClosedString, _removeClosedString] = useLocalStorage(
    STORAGE_KEY,
    ''
  );
  const closed = closedString ? JSON.parse(closedString) : [];

  const handleClose = (id: number) => {
    // Update the state and localstorage for the closed notifications
    const newClosed = [...closed, id];
    setClosedString(JSON.stringify(newClosed));
  };

  return (
    <>
      {notifications
        ?.filter(
          (notification: NotificationType) => !closed.includes(notification.id)
        )
        .map((notification: NotificationType) => (
          <Notification
            key={notification.id}
            content={notification.content}
            contentColor={notification.contentColor}
            backgroundColor={notification.backgroundColor}
            countdown={
              notification.timer && notification.endDate
                ? new Date(notification.endDate)
                : null
            }
            onClose={() => {
              handleClose(notification.id);
            }}
          />
        ))}
    </>
  );
}

export default NotificationBar;
