import { useState } from 'react';
import clsx from 'clsx';

import ArrowDown from '@public/icons/angle-down.svg';
import Heart from '@public/icons/heart.svg';
import Bullet from '@public/icons/bullet.svg';

import { BodyLink } from 'components/common/Body';
import Link from 'components/common/Link';

import { NavigationMenu } from 'interfaces/cms/layout';
import { isAsiadirect, transformLocale } from 'lib';

export interface NavigationProps {
  navigations: Array<NavigationMenu>;
  locale: string;
}

function Desktop({ navigations, locale }: NavigationProps) {
  const [subMenu, setSubMenu] = useState(null);

  const onHoverMenu = (
    index: number,
    hasSubMenu = false,
    onMouseLeave = false
  ) => {
    const currentElem = document.getElementById(
      `menu-list-${index}`
    ).firstElementChild;
    const nextElem = document.getElementById(`menu-list-${index + 1}`)
      ?.firstElementChild;

    currentElem.classList.toggle('border-accordionPanelBg');
    currentElem.classList.toggle('border-grayColor');
    if (nextElem) {
      nextElem.classList.toggle('border-white');
      nextElem.classList.toggle('border-grayColor');
    }

    setSubMenu(hasSubMenu && !onMouseLeave ? index : null);
  };

  const onHoverSubMenu = (
    menuIndex,
    subMenuIndex,
    subMenuLength,
    onMouseLeave = false
  ) => {
    const currentElem = document.getElementById(
      `subMenu-${menuIndex}-${subMenuIndex}`
    )?.firstElementChild;

    if (subMenuIndex === 0 && subMenuLength === 0) return;
    else if (subMenuIndex === 0 && subMenuLength !== 0) {
      currentElem.classList.toggle('border-accordionPanelBg');
      currentElem.classList.toggle('border-grayColor');
    } else {
      const prevElem = document.getElementById(
        `subMenu-${menuIndex}-${subMenuIndex - 1}`
      )?.firstElementChild;

      if (prevElem) {
        prevElem.classList.toggle('border-white');
        prevElem.classList.toggle('border-grayColor');
      }
      if (subMenuIndex !== subMenuLength) {
        currentElem.classList.toggle('border-accordionPanelBg');
        currentElem.classList.toggle('border-grayColor');
      }
    }

    setSubMenu(onMouseLeave ? null : menuIndex);
  };

  return (
    <div
      className={clsx(
        'hidden',
        'lg:flex',
        'container',
        'max-w-full',
        'bg-white',
        'shadow-[0_0.0625px_0.125px_0_rgba(16, 102, 203, 0.05)]',
        'shadow-sm',
        'items-center',
        'justify-center'
      )}
    >
      <div
        className={clsx(
          'hidden lg:flex',
          'w-full',
          'max-w-[90rem]',
          'px-[1rem]',
          'lg:px-[2rem]',
          'xl:px-[3.75rem]',
          'flex-row',
          'justify-center',
          'flex-wrap'
        )}
      >
        {navigations.map((navigation, index) =>
          navigation.subcategory.length ? (
            <span
              className="hover:bg-accordionPanelBg py-[0.844rem] relative"
              key={index}
              id={`menu-list-${index}`}
              onMouseEnter={() => onHoverMenu(index, true)}
              onMouseLeave={() => onHoverMenu(index, true, true)}
            >
              <div
                className={clsx(
                  'border-l border-grayColor border-dashed',
                  'flex flex-row items-center h-[2rem]',
                  index === navigations.length - 1 && 'border-r'
                )}
              >
                <BodyLink
                  textColor="text-navTextColor"
                  isBold
                  style={{ marginLeft: '1.875rem' }}
                >
                  {navigation.title}
                </BodyLink>
                <ArrowDown
                  id={`arrow-${index}`}
                  className={clsx(
                    'fill-navTextColor ml-[0.6rem] mr-[1.875rem]',
                    'transform transition duration-300',
                    subMenu === index && 'rotate-180'
                  )}
                />
              </div>
              <div
                className={clsx(
                  'absolute mt-[0.85rem] w-[15.625rem] bg-white',
                  'rounded-br-[1.25rem] rounded-bl-[1.25rem]',
                  index === navigations.length - 1
                    ? 'rounded-tl-[1.25rem]'
                    : 'rounded-tr-[1.25rem]',
                  'border border-navBarBorder',
                  'shadow-lg',
                  'shadow-[0_10px_20px_rgba(58, 111, 232, 0.20)] z-[15]',
                  subMenu !== index && 'hidden',
                  index === navigations.length - 1 && 'right-0'
                )}
                id={`sub-menu-${index}`}
              >
                {navigation.subcategory.map((category, subMenuIndex) => (
                  <Link
                    href={category.link}
                    isExternalLink={category.type !== 'slug'}
                    locale={transformLocale(locale)}
                    passHref
                    key={subMenuIndex}
                  >
                    <div
                      className={clsx(
                        'hover:bg-accordionPanelBg',
                        subMenuIndex === navigation.subcategory.length - 1 &&
                          'rounded-b-[1.25rem]',
                        subMenuIndex === 0 &&
                          (index === navigations.length - 1
                            ? 'rounded-tl-[1.25rem]'
                            : 'rounded-tr-[1.25rem]')
                      )}
                      id={`subMenu-${index}-${subMenuIndex}`}
                      onMouseEnter={() =>
                        onHoverSubMenu(
                          index,
                          subMenuIndex,
                          navigation.subcategory.length - 1
                        )
                      }
                      onMouseLeave={() =>
                        onHoverSubMenu(
                          index,
                          subMenuIndex,
                          navigation.subcategory.length - 1,
                          true
                        )
                      }
                    >
                      <div
                        className={clsx(
                          'py-[15px] mx-[15px]',
                          subMenuIndex !== navigation.subcategory.length - 1 &&
                            'border-b border-grayColor border-dashed',
                          'flex flex-row items-center'
                        )}
                      >
                        {isAsiadirect() ? (
                          <Bullet className="fill-navTextColor w-[0.875rem] h-[0.875rem] mt-[0.3125rem]" />
                        ) : (
                          <Heart className="fill-navTextColor w-[0.9575rem] h-[0.8125rem]" />
                        )}
                        <span className="text-navTextColor pl-[0.625rem] text-sm">
                          {category.title}
                        </span>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </span>
          ) : (
            <span
              className="hover:bg-accordionPanelBg py-[0.844rem]"
              key={index}
              id={`menu-list-${index}`}
              onMouseEnter={() => onHoverMenu(index)}
              onMouseLeave={() => onHoverMenu(index, false, true)}
            >
              <div
                className={clsx(
                  'border-l border-grayColor border-dashed',
                  'flex flex-row items-center h-[2rem]',
                  index === navigations.length - 1 && 'border-r'
                )}
              >
                <Link
                  href={navigation.pageSlug || navigation.linkUrl}
                  locale={transformLocale(locale)}
                  passHref
                  isExternalLink={
                    !!(navigation.linkUrl && navigation.linkUrl !== '/')
                  }
                  className="mx-[1.875rem]"
                >
                  <BodyLink textColor="text-navTextColor" isBold>
                    {navigation.title}
                  </BodyLink>
                </Link>
              </div>
            </span>
          )
        )}
      </div>
    </div>
  );
}

export default Desktop;
