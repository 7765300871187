import clsx from 'clsx';

export interface BodyLinkProps {
  isBold?: boolean;
  textColor?: string;
  style?: object;
}

function BodyLink({
  children,
  isBold = false,
  textColor = 'text-textColor',
  style,
}: BodyLinkProps & { children?: React.ReactNode }) {
  return (
    <span
      className={clsx(
        'leading-[1.1875rem]',
        textColor,
        'text-sm',
        isBold ? 'font-bold' : 'font-normal'
      )}
      style={style}
    >
      {children}
    </span>
  );
}

export default BodyLink;
