import { useEffect } from 'react';
import Countdown from 'react-countdown';
import clsx from 'clsx';
import Markdown from 'components/common/Markdown';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'components/common/Modal';
import useDisclosure from 'hooks/useDisclosure';
import { PromotionPopupProps } from 'interfaces/cms/common';
import Image from 'components/common/Image';
import ButtonComponent from 'components/common/Button';
import LinkComponent from 'components/common/Link';

function PromotionPopupMenu({
  id,
  title,
  titleColor,
  subtitle,
  subtitleColor,
  image,
  button,
  countdown,
  timerContent,
  timerColor,
  openAfter,
}: PromotionPopupProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const STORAGE_KEY = 'closed:promotionPopups';
    const storage = window.localStorage;
    const closed = JSON.parse(storage.getItem(STORAGE_KEY)) ?? [];

    const handleMouseout = (e: MouseEvent) => {
      if (!e.relatedTarget) {
        onOpen();
        window.removeEventListener('mouseout', handleMouseout);
      }
    };

    if (!closed.includes(id)) {
      setTimeout(() => {
        onOpen();
      }, openAfter * 1000);

      window.addEventListener('mouseout', handleMouseout);
    }

    return window.removeEventListener('mouseout', handleMouseout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>
        <Markdown md={title} textAlign="center" textColor={titleColor} />
      </ModalHeader>
      <ModalBody>
        {(timerContent || countdown) && (
          <div
            className="text-center font-bold mb-2"
            style={{ color: timerColor }}
          >
            {timerContent && `${timerContent}  `}
            {countdown && <Countdown date={countdown} />}
          </div>
        )}
        <Markdown md={subtitle} textAlign="center" textColor={subtitleColor} />
        {image && (
          <div className="mt-5">
            <Image
              src={image.url}
              alt={image.alt}
              width={image.width}
              height={image.height}
              sizes="100vw"
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        {button && (
          <>
            <LinkComponent
              href={
                button.buttonPageSlug
                  ? button.buttonPageSlug.slug
                  : button.buttonUrl
              }
              rel={button.buttonLinkRel}
              isExternalLink={!!button.buttonUrl}
              className="w-full"
            >
              <ButtonComponent {...button} isFullWidth />
            </LinkComponent>

            {button.disclaimer && (
              <div
                className={clsx(
                  'button_disclaimer',
                  'pt-[0.625rem] w-[100%] flex items-start justify-center'
                )}
              >
                <Markdown
                  md={button.disclaimer.title}
                  textColor={button.disclaimer.color}
                  textAlign="center"
                  hasLockIcon={button.disclaimer?.lockIcon}
                />
              </div>
            )}
          </>
        )}
      </ModalFooter>
    </Modal>
  );
}

export default PromotionPopupMenu;
