import clsx from 'clsx';
import Image from 'components/common/Image';
import LineLogo from '@public/icons/line.svg';
import MessengerLogo from '@public/icons/messenger-chat.svg';
import WhatsappLogo from '@public/icons/whatsapp.svg';

const customerPortalUrl = process.env.NEXT_PUBLIC_CUSTOMER_PORTAL;

import {
  ContactButton,
  CustomerPortalButton,
  HamburgerButton,
  Header,
  LanguageButton,
  PhoneButton,
} from '@alphafounders/ui';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { Settings } from 'interfaces/cms/layout';
import Link from 'components/common/Link';
import { useRouter } from 'next/router';
import { isAsiadirect, transformLocale } from 'lib';
import { baseUrl } from '../../../../../config';
import Head from 'next/head';

export interface LayoutHeaderProps extends Settings {
  locale: string;
  slug?: string;
  isMultipleLang?: boolean;
  navigationIsOpen?: boolean;
  toggleNavigation?: () => void;
}

function LayoutHeaderV2({
  headerText,
  openingTimes,
  phone,
  lineUrl,
  messengerUrl,
  slug,
  locale,
  navigationIsOpen,
  toggleNavigation,
  languageSwitch,
  structuredData,
}: Readonly<LayoutHeaderProps>) {
  const router = useRouter();
  const { t } = useTranslation('common');

  const isExpat = slug === 'expat-insurance' || slug === 'oversea-insurance';

  const Logo = useCallback(
    () => (
      <Image
        src={process.env.NEXT_PUBLIC_RABBIT_LOGO_IMAGE}
        alt="Rabbit Care Logo"
        fill
        priority
        loading="eager"
        placeholder="empty"
      />
    ),
    []
  );

  const renderContactButtons = () => {
    const buttons = [];

    if (isExpat) {
      buttons.push({
        href: 'https://wa.me/66942152140',
        imagePath: `${baseUrl}/icons/whatsapp.svg`,
        altText: 'Whatsapp',
        ariaLabel: 'Whatsapp Live Chat',
        icon: <WhatsappLogo />,
      });
      buttons.push({
        href: 'https://line.me/ti/p/~@rabbitexpat',
        imagePath: `${baseUrl}/icons/line.svg`,
        altText: 'Line',
        ariaLabel: 'LINE Add Friend',
        icon: <LineLogo />,
      });
    } else {
      if (lineUrl) {
        buttons.push({
          href: lineUrl,
          imagePath: `${baseUrl}/icons/line.svg`,
          altText: 'Line',
          ariaLabel: 'LINE Add Friend',
          icon: <LineLogo />,
        });
      }
      if (messengerUrl) {
        buttons.push({
          href: messengerUrl,
          imagePath: `${baseUrl}/icons/messenger.svg`,
          altText: 'Facebook',
          ariaLabel: 'MSN Messenger',
          icon: <MessengerLogo />,
        });
      }
    }

    return buttons.map((props) => (
      <ContactButton
        className={clsx(
          'bg-primaryColor border-primaryColor',
          isAsiadirect() &&
            'hover:md:border-secondaryColor hover:md:bg-secondaryColor'
        )}
        key={props.href}
        {...props}
      />
    ));
  };

  const handleClick = useCallback(
    (code: string) => {
      router.push(slug, slug, { locale: code });
    },
    [router, slug]
  );

  const renderLocaleButtons = () => {
    if (!languageSwitch) return null;
    return languageSwitch.map((props) => (
      <LanguageButton
        dataTestId="lang-button"
        selected={props.code === locale}
        text={props.displayText}
        key={props.code}
        onClick={handleClick}
        {...props}
      />
    ));
  };

  const phoneText = () => {
    if (isExpat) return '02-089-2287';
    return phone;
  };

  return (
    <>
      <Head>
        {Boolean(structuredData) && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
          />
        )}
      </Head>

      <div
        data-testid="header-v2"
        className="px-4 lg:px-0 bg-white sticky top-0 z-20 h-[3.75rem] items-center flex w-full shadow-sm"
      >
        <Header
          menuButton={
            <HamburgerButton
              className="lg:collapse stroke-primaryColor"
              isOpen={navigationIsOpen}
              onClick={toggleNavigation}
            />
          }
          icon={
            <Link href="/" locale={transformLocale(locale)}>
              <div
                className={clsx(
                  'relative',
                  isAsiadirect()
                    ? 'w-[150px] h-[35px] lg:w-[180px] lg:h-[40px]'
                    : 'w-[91px] sm:w-[135px] h-[15px] sm:h-[20px] lg:w-[180px] lg:h-[28px]'
                )}
              >
                <Logo />
              </div>
            </Link>
          }
          label={headerText}
          phoneButton={
            phone &&
            openingTimes && (
              <PhoneButton
                number={phoneText()}
                subText={openingTimes}
                className="bg-primaryColor border-primaryColor md:fill-primaryColor md:text-primaryColor sm:hover:border-primaryColor"
              />
            )
          }
          customerPortalButton={
            customerPortalUrl && (
              <CustomerPortalButton
                href={`${customerPortalUrl}/${locale}`}
                text={t('customerPortal_text')}
              />
            )
          }
          contactButtons={renderContactButtons()}
          localeButtons={renderLocaleButtons()}
        />
      </div>
    </>
  );
}

export default LayoutHeaderV2;
