import clsx from 'clsx';

export interface Body3Props {
  isBold?: boolean;
  textColor?: string;
  style?: object;
}

function Body3({
  children,
  isBold = false,
  textColor = 'text-textColor',
  style,
}: Body3Props & { children?: React.ReactNode }) {
  return (
    <span
      className={clsx(
        'leading-[0.875rem]',
        textColor,
        'text-tiny',
        isBold ? 'font-bold' : 'font-normal'
      )}
      style={style}
    >
      {children}
    </span>
  );
}

export default Body3;
