import { useEffect, useState } from 'react';
import clsx from 'clsx';

import Heart from '@public/icons/heart.svg';
import ArrowDown from '@public/icons/angle-down.svg';
import LanguageIcon from '@public/icons/language.svg';

import Link from 'components/common/Link';

import { LanguageSwitch, NavigationMenu } from 'interfaces/cms/layout';
import { transformLocale } from 'lib';

function Title({ title, className = '' }) {
  return (
    <span className={clsx('text-navTextColor font-bold text-base', className)}>
      {title}
    </span>
  );
}

function Container({ children, onClickItem }) {
  return (
    <div
      className={clsx(
        'py-[0.9375rem] px-[1.25rem]',
        'border-b border-grayColor',
        'flex flex-row items-center justify-between cursor-pointer'
      )}
      onClick={onClickItem}
    >
      {children}
    </div>
  );
}

export interface NavigationProps {
  navigations: Array<NavigationMenu>;
  locale: string;
  slug: string;
  isMultipleLang: boolean;
  isOpen: boolean;
  availableLanguages?: Array<LanguageSwitch>;
  onClick?: () => void;
}

function Mobile({
  navigations,
  locale,
  slug,
  isMultipleLang,
  isOpen,
  onClick,
  availableLanguages,
}: NavigationProps) {
  const [menuOpenId, setMenuOpenId] = useState('');

  useEffect(() => {
    setMenuOpenId('');
  }, [isOpen]);

  const onOpenSubMenu = (id) => {
    setMenuOpenId(menuOpenId === id ? '' : id);
  };

  const handleSubMenuClick = () => {
    setMenuOpenId('');
    onClick();
  };

  return (
    <>
      {navigations.map((navigation) =>
        navigation.subcategory.length ? (
          <span key={navigation.id}>
            <Container
              onClickItem={() => onOpenSubMenu(navigation.id.toString())}
            >
              <Title title={navigation.title} />
              {navigation.subcategory.length > 0 && (
                <ArrowDown
                  className={clsx(
                    'fill-navTextColor',
                    menuOpenId === navigation.id.toString() && 'rotate-180'
                  )}
                />
              )}
            </Container>
            {menuOpenId === navigation.id.toString() &&
              navigation.subcategory.map((category, subMenuIndex) => (
                <div
                  key={category.id}
                  className={clsx('px-[1.25rem]', 'bg-accordionPanelBg')}
                  onClick={handleSubMenuClick}
                >
                  <Link
                    href={category.link}
                    isExternalLink={category.type !== 'slug'}
                    locale={transformLocale(locale)}
                    passHref
                    key={subMenuIndex}
                  >
                    <div
                      className={clsx(
                        'py-[0.9rem] px-[1rem]',
                        subMenuIndex !== navigation.subcategory.length - 1 &&
                          'border-b border-borderColor border-dashed',
                        'flex flex-row items-center'
                      )}
                    >
                      <Heart className="fill-navTextColor mr-[0.6rem] w-[0.9375rem] h-[0.8125]" />
                      <span className="text-navTextColor text-sm">
                        {category.title}
                      </span>
                    </div>
                  </Link>
                </div>
              ))}
          </span>
        ) : (
          <Link
            key={navigation.id}
            href={navigation.pageSlug || navigation.linkUrl}
            locale={transformLocale(locale)}
            passHref
            isExternalLink={navigation.linkUrl && navigation.linkUrl !== '/'}
          >
            <Container
              {...{
                onClickItem: () => {
                  setMenuOpenId('');
                  onClick();
                },
              }}
            >
              <Title title={navigation.title} />
            </Container>
          </Link>
        )
      )}

      {isMultipleLang && (
        <Container key="language" onClickItem={() => onOpenSubMenu('language')}>
          <div className="flex flex-row items-center">
            <LanguageIcon />
            <Title title={locale} className="uppercase ml-3" />
          </div>
          <ArrowDown
            className={clsx(
              'fill-navTextColor',
              menuOpenId === 'language' && 'rotate-180'
            )}
          />
        </Container>
      )}
      {menuOpenId === 'language' &&
        availableLanguages &&
        availableLanguages.map((lang, index) => (
          <div
            key={`lang-${transformLocale(lang.code)}`}
            className={clsx('px-[1.25rem]', 'bg-accordionPanelBg')}
          >
            <Link href={slug} locale={transformLocale(lang.code)} passHref>
              <div
                className={clsx(
                  'py-[0.9rem] px-[1rem]',
                  index !== availableLanguages.length - 1 &&
                    'border-b border-borderColor border-dashed',
                  'flex flex-row items-center'
                )}
              >
                <Heart className="fill-navTextColor mr-[0.6rem] w-[0.9375rem] h-[0.8125]" />
                <span className="text-navTextColor text-sm">
                  {lang.displayText}
                </span>
              </div>
            </Link>
          </div>
        ))}
    </>
  );
}

export default Mobile;
