import { useState } from 'react';

import ParagraphHeader from 'components/ParagraphHeader';
import DesktopSection from 'components/DynamicContent/LinkBuilding/Desktop';
import MobileSection from 'components/DynamicContent/LinkBuilding/Mobile';

import { LinkBuildingProps } from 'interfaces/cms/content';

function LinkBuilding({ header, section }: LinkBuildingProps) {
  const [linkOpenIds, setLinkOpenIds] = useState([]);

  const onOpenLinkSection = (id) => {
    if (linkOpenIds.some((openId) => openId === id)) {
      setLinkOpenIds((prev) => prev.filter((openId) => openId !== id));
    } else {
      setLinkOpenIds([...linkOpenIds, id]);
    }
  };

  return (
    <div
      className="py-[1.875rem] px-[0.9375rem] bg-footerLinkBuildingBg"
      id="staticLinkBuilding"
    >
      <div className="max-w-[69.375rem] mx-auto">
        {header && (
          <div className="my-[1.875rem]">
            <ParagraphHeader
              {...header}
              titleColor="#ffffff"
              subtitleColor="#ffffff"
            />
          </div>
        )}

        {section && (
          <>
            <div className="block md:hidden">
              {section.map((data) => (
                <MobileSection
                  key={data.id}
                  {...{ ...data, onOpenLinkSection, linkOpenIds }}
                />
              ))}
            </div>
            <div className="hidden md:block">
              {section.map((data) => (
                <DesktopSection key={data.id} {...data} />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default LinkBuilding;
