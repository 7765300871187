import { useRouter } from 'next/router';
import clsx from 'clsx';

import ArrowDown from '@public/icons/angle-down.svg';

import Link from 'components/common/Link';

import { LinkBuildingSectionProps } from 'interfaces/cms/content';
import { transformLocale } from 'lib';

interface MobileProps extends LinkBuildingSectionProps {
  linkOpenIds: Array<string>;
  onOpenLinkSection: (id: string) => void;
}

function Mobile({
  id,
  subsectionTitle,
  contentLink,
  linkOpenIds,
  onOpenLinkSection,
}: MobileProps) {
  const { locale } = useRouter();
  const hasOpenLinkSection = linkOpenIds.some((openId) => openId === id);

  return (
    <>
      <div
        id={`mobile-link-building-${id}`}
        className={clsx(
          'py-[0.9375rem] cursor-pointer',
          'border-b border-dashed border-grayColor',
          'flex flex-row items-center justify-between'
        )}
        onClick={() => onOpenLinkSection(id)}
      >
        <span className="text-white font-bold">{subsectionTitle}</span>
        <ArrowDown
          className={clsx(
            'fill-white transition-all ease-in-out duration-300',
            hasOpenLinkSection ? 'rotate-180' : 'rotate-0'
          )}
        />
      </div>

      <div
        className={clsx(
          'transition-all ease-in-out overflow-hidden',
          hasOpenLinkSection
            ? 'max-h-screen duration-1000'
            : 'max-h-0 duration-300'
        )}
      >
        <div className="flex flex-col px-[1.25rem] py-[0.9375rem]">
          {contentLink.map((subsection) => (
            <Link
              key={subsection.id}
              href={subsection.link}
              rel={subsection.linkRel}
              isExternalLink={subsection.type !== 'slug'}
              locale={transformLocale(locale)}
              className="my-[0.4375rem] text-sm text-white"
            >
              {subsection.title}
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

export default Mobile;
