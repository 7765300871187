import BaseImage from 'next/image';
import type { ImageProps } from 'next/image';
import { cmsUrl } from '../../../../config';

const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#999" offset="20%" />
      <stop stop-color="#888" offset="50%" />
      <stop stop-color="#999" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#999" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str: string) =>
  typeof window === 'undefined'
    ? Buffer.from(str).toString('base64')
    : window.btoa(str);

function Image(props: ImageProps) {
  const { src, quality, placeholder, ...rest } = props;
  const _src = typeof src === 'string' ? new URL(src, cmsUrl).href : src;
  return (
    <BaseImage
      src={_src}
      {...rest}
      quality={quality ?? 80}
      placeholder={
        placeholder ??
        `data:image/svg+xml;base64,${toBase64(shimmer(700, 475))}`
      }
    />
  );
}

export default Image;
