import { baseUrl, topLevelDomain } from '../../config';

const baseSize = 16;

export const rem = (pxValue: number) => `${pxValue / baseSize}rem`;

export function isAsiadirect() {
  return topLevelDomain === 'asiadirect';
}

export function transformLocaleForApi(locale: string) {
  // Great hack
  return isAsiadirect() ? 'th' : locale;
}

export function transformLocale(locale: string) {
  return isAsiadirect() ? 'asiadirect' : locale;
}

export const getWebsiteUrl = (asPath: string) => {
  return `${baseUrl}${asPath.split('?')[0]}`;
};

export default { rem, getWebsiteUrl, isAsiadirect, transformLocale };
