import React, { useEffect } from 'react';
import clsx from 'clsx';
import dynamic from 'next/dynamic';

import AngleRight from '@public/icons/angle-right.svg';

import ImageComponent from 'components/common/Image';
import Link from 'components/common/Link';
import Markdown from 'components/common/Markdown';

import { rem, transformLocale } from 'lib';
import { Footer } from 'interfaces/cms/layout';
import styles from './Footer.module.scss';

const InfobipWidget = dynamic(() => import('../InfobipWidget'));
const BackToTop = dynamic(() => import('components/Layout/BackToTop'));

export interface LayoutFooterProps extends Footer {
  locale: string;
  enableInfobip?: boolean;
}

function FooterLayout({
  logo,
  links,
  content,
  locale,
  enableInfobip,
}: Readonly<LayoutFooterProps>) {
  return (
    <>
      <BackToTop />
      {enableInfobip && <InfobipWidget />}
      <div className={clsx('bg-footerBg')}>
        <div
          className={clsx(
            'max-w-[90rem] w-full mx-auto',
            'pt-[0.625rem] px-[0.9375rem] pb-[2.8125rem] md:p-[3.125rem]'
          )}
        >
          <div
            className={clsx(
              'flex flex-col items-start',
              'md:flex-row md:items-center',
              'mb-[2rem]'
            )}
          >
            <div className="order-2 md:order-1">
              <ImageComponent
                {...logo}
                src={logo?.url}
                width={95}
                height={34.7}
                alt={logo?.alt || logo?.name}
              />
            </div>
            <div
              className={clsx(
                'flex flex-col md:flex-row',
                'order-1 md:order-2 w-full',
                'mb-[2rem] md:mb-0'
              )}
            >
              {links.map((link) => (
                <div key={link.id}>
                  <Link
                    isExternalLink={link.type !== 'slug'}
                    href={link.link}
                    locale={transformLocale(locale)}
                    passHref
                    className={clsx(
                      'text-footerLink text-sm',
                      'hidden md:block ml-[0.9375rem]'
                    )}
                    dataTestId="desktop-link"
                  >
                    {link.title}
                  </Link>
                  <Link
                    isExternalLink={link.type !== 'slug'}
                    href={link.link}
                    locale={transformLocale(locale)}
                    passHref
                    className={clsx(
                      'py-[0.9375rem]',
                      'md:hidden',
                      'border-b border-dashed border-footerBorderLink'
                    )}
                  >
                    <div className="flex flex-row items-center justify-between">
                      <span className="text-sm text-footerLink">
                        {link.title}
                      </span>
                      <AngleRight className="fill-footerLink" />
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.footer__markdown}>
            <Markdown
              textAlign="left"
              md={content}
              className="text-footerText"
              fontSize={rem(14)}
              lineHeight={rem(20)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterLayout;
