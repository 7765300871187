import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { CookieBannerProps } from 'interfaces/cms/layout';
import { getCookieDomain } from '../../../lib/helper';

export function CookieBanner({ content, btnLabel }: CookieBannerProps) {
  const [isOpenCookieBanner, setIsOpenCookieBanner] = useState(true);

  useEffect(() => {
    const rcPDPA = Cookies.get('Rc_PDPA');
    setIsOpenCookieBanner(!rcPDPA);
  }, []);

  const handleAcceptCookie = () => {
    const now = new Date();
    const cookieValue = {
      categories: [],
      revision: 0,
      data: null,
      rfc_cookie: true,
      consent_date: now,
      last_consent_update: now,
    };

    Cookies.set('Rc_PDPA', JSON.stringify(cookieValue), {
      expires: 30,
      path: '/',
      domain: '.' + getCookieDomain(),
    });
    setIsOpenCookieBanner(false);
  };

  return (
    <>
      {isOpenCookieBanner && (
        <div className="fixed bottom-0 w-full flex justify-center z-[21370001] p-[10px]">
          <div className="flex flex-col md:flex-row bg-white py-[20px] px-[1rem] max-w-[46rem] rounded-[16px] shadow-[0px_7px_15px_rgba(42,49,203,0.2)]">
            <div className="text-sm leading-[21px]">
              <div
                className="text-left font-size-sm"
                dangerouslySetInnerHTML={{
                  __html: `${content} `,
                }}
              />
            </div>
            <button
              data-testid="btn-accept-cookies"
              className="md:min-w-[7rem] md:ml-[1rem] mt-[1rem] md:mt-0 font-bold uppercase bg-buttonPrimaryBg hover:bg-buttonPrimaryBgHover text-white rounded-[10px] py-[13px] px-[27px] text-sm h-[50px]"
              onClick={handleAcceptCookie}
            >
              {btnLabel}
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default CookieBanner;
