import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { matchParamsAndLink } from '@careos/utils';

export default function useQueryParams(
  useUtmParams: boolean,
  buttonLink: string
): string {
  const { query } = useRouter();
  const [buttonUrl, setButtonUrl] = useState<string>(buttonLink);

  useEffect(() => {
    const updatedUrl = matchParamsAndLink(useUtmParams, buttonLink, query);
    setButtonUrl(updatedUrl);
  }, [query]);

  return buttonUrl;
}
