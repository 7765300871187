import clsx from 'clsx';

export interface Body1Props {
  isBold?: boolean;
  textColor?: string;
  style?: object;
}

function Body1({
  children,
  isBold = false,
  textColor = 'text-textColor',
  style,
}: Body1Props & { children?: React.ReactNode }) {
  return (
    <span
      className={clsx(
        'leading-6',
        'text-base',
        textColor,
        isBold ? 'font-bold' : 'font-normal'
      )}
      style={style}
    >
      {children}
    </span>
  );
}

export default Body1;
