import clsx from 'clsx';
import showdown from 'showdown';
import { Property } from 'csstype';
import styles from './Markdown.module.scss';

export interface MarkdownProps {
  textAlign: string;
  className?: string;
  fontSize?: string;
  lineHeight?: string;
  md?: string;
  fontFamily?: string;
  hasLockIcon?: boolean;
  pt?: string;
  textColor?: string;
}

const lockIcon =
  '<svg width="8.85" height="11.07" viewBox="0 0 10.086 12.607" style="display: inline-block; margin-right: 5px; margin-bottom: 2px;" fill="currentColor"><path d="M9.043,8.934a.63.63,0,0,0-.63.63v1.891a.63.63,0,0,0,1.261,0V9.564A.63.63,0,0,0,9.043,8.934Zm3.152-2.521V5.152a3.152,3.152,0,0,0-6.3,0V6.413A1.891,1.891,0,0,0,4,8.3v4.413a1.891,1.891,0,0,0,1.891,1.891h6.3a1.891,1.891,0,0,0,1.891-1.891V8.3A1.891,1.891,0,0,0,12.195,6.413ZM7.152,5.152a1.891,1.891,0,1,1,3.782,0V6.413H7.152Zm5.673,7.564a.63.63,0,0,1-.63.63h-6.3a.63.63,0,0,1-.63-.63V8.3a.63.63,0,0,1,.63-.63h6.3a.63.63,0,0,1,.63.63Z" transform="translate(-4 -2)" /></svg>';

function Markdown({
  textAlign,
  className,
  fontSize,
  lineHeight,
  md,
  fontFamily,
  hasLockIcon = false,
  pt,
  textColor,
}: MarkdownProps) {
  const converter = new showdown.Converter({
    strikethrough: true,
    splitAdjacentBlockquotes: true,
    noHeaderId: true,
  });

  let markdownData = md;
  if (hasLockIcon) {
    // eslint-disable-next-line no-param-reassign
    markdownData = `${lockIcon}${md}`;
  }

  return (
    <div
      className={clsx(
        `markdown_main-container ${styles.markdown__title}`,
        className
      )}
      style={{
        textAlign: textAlign as Property.TextAlign,
        fontSize: fontSize as Property.FontSize,
        lineHeight: lineHeight as Property.LineHeight,
        fontFamily: fontFamily as Property.FontFamily,
        paddingTop: pt,
        color: textColor,
      }}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: converter.makeHtml(markdownData),
      }}
    />
  );
}

export default Markdown;
